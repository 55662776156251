body {
  background-color: whitesmoke;
}

.todo-list {
  background-color: white;
  margin: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #efefef;
  box-shadow:
  /* The top layer shadow */
      0 1px 1px rgba(0,0,0,0.15),
          /* The second layer */
      0 10px 0 -5px #eee,
          /* The second layer shadow */
      0 10px 1px -4px rgba(0,0,0,0.15),
          /* The third layer */
      0 20px 0 -10px #eee,
          /* The third layer shadow */
      0 20px 1px -9px rgba(0,0,0,0.15);
  padding: 30px;
}

.todo-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 20px 0;
  width: 70%;
  border-bottom: 1px solid #cecece;
  font-family: Roboto, sans-serif;
  font-weight: 100;
  font-size: 15px;
  color: #333333;
}

input[type=checkbox] {
  margin-right: 10px;
  font-size: 30px;
}

input[type=checkbox]:focus {
  outline: 0;
}